import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { typography } from "styled-system"
import slugify from "slugify"

import LeftColumn from "~components/leftColumn"
import FullColumn from "~components/fullColumn"

import SignupForm from "./components/signupForm/_signupForm.js"

const Wrapper = styled.div`
  padding: 0.75rem;
  @media (max-width: 900px) {
    margin-bottom: 4rem;
  }
`

const Title = styled.h1`
  ${typography}
  box-sizing: border-box;
  font-size: 2.5rem;
  margin-bottom: -0.4rem;
  font-weight: normal;
  @media (max-width: 900px) {
    margin-top: 1.5rem;
  }
`

const SubTitle = styled.p`
  ${typography}
  font-weight: bold;
  margin-bottom: 1rem;
  flex-basis: 33%;
  font-size: 1.25rem;
`

const Container = styled.div`
  margin: 1rem 0;
  padding: 1rem 0;
  border-top: 2px black solid;
  border-bottom: 2px black solid;
`

Container.Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
`

Container.SubTitle = styled.p`
  ${typography}
  font-weight: bold;
  margin-bottom: 1rem;
  flex-basis: 33%;
  font-size: 1rem;
`

const Campaigns = styled.div`
  margin-top: 1rem;
  column-count: 2;
  column-gap: 1.5rem;
  @media (max-width: 900px) {
    column-count: 1;
  }
`

const Campaign = styled.a`
  display: block;
  width: fit-content;
  max-width: 700px;
  margin-bottom: 1rem;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition: border-color 0.3s;
  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.background};
  }
`
const SeeMoreButton = styled.button`
  -webkit-appearance: none;
  background: none;
  border: none;
  padding: 0.5rem 0.8rem;
  margin: 0;
  margin-top: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.background};
  font-family: Optima;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.foreground};
  }
`

export default ({
  data: {
    allMailchimpCampaign: { edges: campaigns },
  },
}) => {
  const [filteredCampaigns, setFilteredCampaigns] = useState(
    campaigns.slice(0, 12)
  )
  const [showAllCampaigns, setShowAllCampaigns] = useState(false)

  useEffect(() => {
    if (showAllCampaigns) {
      setFilteredCampaigns(campaigns)
    }
  }, [showAllCampaigns])
  return (
    <Wrapper>
      <LeftColumn>
        <Title fontFamily={"moonbase"}>OPx</Title>
        <Container>
          <Container.SubTitle fontFamily={"optima"}>
            OPx is a curated newsletter.
          </Container.SubTitle>
          <Container.Paragraph>
            Under the radar music & art news with event recommendations.
            <br />
          </Container.Paragraph>
          <Container.Paragraph>Every Friday.</Container.Paragraph>
          <SignupForm />
        </Container>
      </LeftColumn>
      <FullColumn>
        <SubTitle fontFamily={"optima"}>Archive</SubTitle>
        <Campaigns>
          {filteredCampaigns.map(({ node: campaign }) => (
            <Campaign
              href={`https://mailchi.mp/offline-projects/${slugify(
                campaign.settings.subject_line
                  .replace(/['/]/gi, "")
                  .replace(/[^0-9a-z]/gi, " "),
                { replacement: "-", lower: true }
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              key={campaign.id}
            >
              {campaign.settings.subject_line}
            </Campaign>
          ))}
        </Campaigns>
        {!showAllCampaigns && (
          <SeeMoreButton
            onClick={() => {
              setShowAllCampaigns(true)
            }}
          >
            See More
          </SeeMoreButton>
        )}
      </FullColumn>
    </Wrapper>
  )
}
export const query = graphql`
  query NewsletterArchiveQuery {
    allMailchimpCampaign {
      edges {
        node {
          id
          html
          settings {
            subject_line
            preview_text
          }
          send_time
        }
      }
    }
  }
`
