import React from "react"
import styled from "styled-components"

const EmailForm = styled.form``
EmailForm.Container = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: baseline;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
`
EmailForm.Label = styled.label`
  display: block;
  margin-right: 1rem;
  font-size: 1rem;
`
EmailForm.Input = styled.input`
  display: block;
  background: none;
  ${"" /* height: 2rem; */}
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
  font-family: "Optima";
  border: 1px solid ${({ theme }) => theme.colors.yellow};
  flex-grow: 1;
`
EmailForm.Submit = styled.button`
  -webkit-appearance: none;
  background: none;
  border: none;
  padding: 0.5rem 0.8rem;
  margin: 0;
  border: 1px solid ${({ theme }) => theme.colors.background};
  font-family: Optima;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.foreground};
  }
`

export default () => {
  return (
    <EmailForm
      // eslint-disable-next-line no-secrets/no-secrets
      action="https://offline-projects.us14.list-manage.com/subscribe/post?u=30f169a672211d7793db6b3e5&amp;id=e080a92160"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate
    >
      <EmailForm.Container>
        <EmailForm.Label htmlFor="mce-EMAIL">Get OPx for free:</EmailForm.Label>
        <EmailForm.Input
          type="email"
          name="EMAIL"
          id="mce-EMAIL"
          placeholder="Email Address"
          required
          placeholderTextColor="red"
        />
      </EmailForm.Container>
      <EmailForm.Submit
        type="submit"
        value="Subscribe"
        name="subscribe"
        id="mc-embedded-subscribe"
      >
        Subscribe
      </EmailForm.Submit>
    </EmailForm>
  )
}
